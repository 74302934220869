<!-- 考试管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">学员管理</div>
      <div>
        <div>
          <!-- <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 67px"
            class="cinput"
            @click='dialogVisible=true'
            >+添加门店</el-button
          > -->
          <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button label="当前学员"></el-radio-button>
            <el-radio-button label="历史学员"></el-radio-button>
            <!-- <el-radio-button label="学员"></el-radio-button>
            <el-radio-button label="院长"></el-radio-button>
            <el-radio-button label="审核员"></el-radio-button> -->
          </el-radio-group>
        </div>
        <div>
             <el-select
            v-model="value"
            placeholder="等级筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="value"
            placeholder="地区筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入姓名"
              class="cinput ac"
              @keyup.enter.native="search"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="search"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="date" label="姓名" align="center" width="180">
        </el-table-column>
        <el-table-column prop="name" align="center" label="学员等级">
        </el-table-column>
        <el-table-column prop="address" align="center" label="地区">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template>
            <div>
              <el-link type="primary" :underline="false" style="margin: 0 25px" @click='details'
                >查看详情</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="100"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="562px"
      :before-close="handleClose"
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          margin-bottom:28px
        "
      >
        <div class="line"></div>
        <div>学员详情</div>
      </div>
     <el-form label-position="right" label-width="100px" >
  <div style="display:flex;justify-content:space-between">
      <el-form-item label="角色：">
    <div class='fdiv'>
        学员
    </div>
  </el-form-item>
  <el-form-item label="角色：">
    <div class='fdiv'>
        学员
    </div>
  </el-form-item>
  <el-form-item label="角色：">
    <div class='fdiv'>
        学员
    </div>
  </el-form-item>
  </div>
  <el-form-item label="姓名：">
    <div class='fdiv'>悠米</div>
  </el-form-item>
  <el-form-item label="性别：">
    <div class='fdiv'>女</div>
  </el-form-item>
   <el-form-item label="年龄：">
    <div class='fdiv'>23</div>
  </el-form-item>
  <el-form-item label="手机号：">
    <div class='fdiv'>13067505175</div>
  </el-form-item>
  <el-form-item label="身份证：">
    <div class='fdiv'>360222199611141234</div>
  </el-form-item>
  <el-form-item label="户籍地：">
    <div class='fdiv'>江西景德镇</div>
  </el-form-item>
</el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio1:'1',
      dialogVisible: false,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      currentPage: 1,
      radio: "当前学员",
      input: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      if (newdata == "已发布") {
        this.isshow2 = true;
      } else {
        this.isshow2 = false;
      }
    },
  },
  methods: {
      details(){
          this.dialogVisible=true
      },
    handleClose() {
      this.dialogVisible = false;
    },
    search() {
      console.log("我是搜索");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__label{
    font-size: 20px;
    font-weight: bold;
}
.fdiv{
    font-size: 20px;
}
/deep/.dialog-footer{
  text-align: center;
}
.inp /deep/.el-input__inner{
  border: none;
  font-size: 20px;
}
.inp2 /deep/.el-input__inner,/deep/.el-radio__label{
  // border: none;
  font-size: 20px;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
/deep/.el-dialog__body {
//   padding: 30px 36px;
}
/deep/.el-radio-group span {
  width: 106px;
}
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
